import React from 'react'
import classNames from 'classnames'

import Page from '../../templates/Page'
import PageSection from '../../templates/PageSection'

class Privacy extends React.Component {
  render() {
    return (
      <Page
        className={classNames('legal', 'privacy', this.props.className)}
        headerTitle="Privacy Policy"
        headerSubtitle="Effective as of 19 November 2019"
      >
        <PageSection>
          <h3>1. Introduction</h3>
          <p>Thanks for choosing Live365!</p>
          <p>
            At Live365, we want to give you the best possible experience to
            ensure that you enjoy our service today, tomorrow, and in the
            future. Your privacy and the security of your personal data is,
            and will always be, enormously important to us. So, we want to
            transparently explain how and why we gather, store, share and use 
            your personal data - as well as outline and choices you have around 
            when and how you choose to share your personal data.
          </p>
          <p>
            That is our objective, and this Privacy Policy (“Policy”) will
            explain exactly what we mean in further detail below.
          </p>
        </PageSection>

        <PageSection>
          <h3>2. About this Policy</h3>

          <p>
            This Policy sets out the essential details relating to your personal
            data relationship with Live365. The Policy applies to all Live365
            services and any associated services (referred to as the ‘Live365
            Service’). The terms governing your use of the Live365 Service are
            defined in our{' '}
            <a href="/legal/terms" target="_blank" rel="noopener noreferral">
              Terms of Service
            </a>
            .
          </p>

          <p>
            From time to time, we may develop new or offer additional services.
            If the introduction of these new or additional services results in
            any change to the way we collect or process your personal data we
            will provide you with more information and additional terms or
            policies. Unless stated otherwise when we introduce these new or
            additional services, they will be subject to this Policy.
          </p>

          <p>The aim of this Policy is to:</p>
          <ol>
            <li>
              Ensure that you understand what personal data we collect about
              you, the reasons why we collect and use it, and who we share it
              with;
            </li>
            <li>
              Explain the way we use the personal data that you share with us in
              order to give you a great experience when you are using the
              Live365 Service; and
            </li>
            <li>
              Explain your rights and choices in relation to the personal data
              we collect and process about you and how we will protect your
              privacy.
            </li>
          </ol>

          <p>
            We hope this helps you to understand our privacy commitments to you.
            For information on how to contact us if you ever have any questions
            or concerns, please see the ‘How to Contact Us’ section below.
            Alternatively, if you do not agree with the content of this Policy,
            then please remember it is your choice whether you want to use the
            Live365 Service.
          </p>
        </PageSection>

        <PageSection>
          <h3>
            3. Your rights and your preferences: Giving you choice and control
          </h3>

          <p>
            You may be aware that a new European Union law, called the General
            Data Protection Regulation or "GDPR" gives certain rights to
            individuals in relation to their personal data. Accordingly, we have
            implemented additional transparency and have the ability to allow
            you to remove data you submitted from the Live365 Service at your
            own wish. As available and except as limited under applicable law,
            the rights afforded to individuals are:
          </p>
          <ul>
            <li>
              Right of Access - the right to be informed of and request access
              to the personal data we process about you;
            </li>
            <li>
              Right to Rectification - the right to request that we amend or
              update your personal data where it is inaccurate or incomplete;
            </li>
            <li>
              Right to Erasure - the right to request that we delete your
              personal data;
            </li>
            <li>
              Right to Restrict - the right to request that we temporarily or
              permanently stop processing all or some of your personal data;
            </li>
            <li>
              Right to Object -{' '}
              <ul>
                <li>
                  the right, at any time, to object to us processing your
                  personal data on grounds relating to your particular
                  situation;
                </li>
                <li>
                  the right to object to your personal data being processed for
                  direct marketing purposes;
                </li>
              </ul>
            </li>
            <li>
              Right to Data Portability - the right to request a copy of your
              personal data in electronic format and the right to transmit that
              personal data for use in another party’s service;
            </li>
          </ul>

          <p>
            If you have any questions about your privacy, your rights, or how to
            exercise them, please contact us at privacy@live365.com. We will
            respond to your request within a reasonable period of time upon
            verification of your identity.
          </p>
        </PageSection>

        <PageSection>
          <h3>4. How do we collect your personal data?</h3>

          <p>We collect your personal data in the following ways:</p>
          <ol>
            <li>
              When you sign up for the Live365 Service - when you sign up to the
              Live365 Service, we collect certain personal data so you can use
              the Live365 Service such as your email address, name, address, and
              phone.
            </li>
            <li>
              From third parties - we will receive personal data about you and
              your activity from third parties, including advertisers and
              partners we work with in order to provide you with the Live365
              Service (please see ‘Sharing your personal data’ section  below).
              We will use this personal data either where you have provided your
              consent to the third party or to Live365 to that data sharing
              taking place or where Live365 has a legitimate interest to use the
              personal data in order to provide you with the Live365 Service.
            </li>
          </ol>

          <p>
            We use anonymized and aggregated information for purposes that
            include testing our IT systems, research, data analysis, creating
            marketing and promotion models, improving the Live365 Service, and
            developing new features and functionality within the Live365
            Service.
          </p>
        </PageSection>

        <PageSection>
          <h3>5. What personal data do we collect from you?</h3>

          <p>
            We have set out in the tables below the categories of personal data
            we collect and use about you:
          </p>
          <table>
            <tr>
              <th>Categories of personal data</th>
              <th>Description of category</th>
            </tr>
            <tr>
              <td>Account Registration Data</td>
              <td>
                <p>
                  If you register with Live365, you must provide us with your
                  first + last name, a valid email address, and a password to
                  create your account. You may additionally provide your mailing
                  address and phone number. When creating a station, you must
                  provide us with the station name, website url, logo
                  (optionally), station profile cover art (optionally), genres,
                  description (optional), and links to your social media account
                  (optional).
                </p>
              </td>
            </tr>
            <tr>
              <td>Subscription</td>
              <td>
                <p>
                  When signing up for a paid package, you will be required to
                  submit your credit card number so our third party service
                  provider can process payments related to the Live365 Service.
                </p>
              </td>
            </tr>
            <tr>
              <td>Communication</td>
              <td>
                <p>
                  When communicating with Live365 staff, such as through Live
                  Chat or a Support Ticket, we collect and log the information
                  you submit to us. If subscribing to a Newsletter, we will
                  collect information from you such as your email address.
                </p>
              </td>
            </tr>
            <tr>
              <td>Location Information</td>
              <td>
                <p>
                  We infer your location by utilizing your IP address to
                  determine a general geographic region. This information is
                  used for advertising targeting and compliance with regulatory
                  laws as it relates to music copyrights.
                </p>
              </td>
            </tr>
            <tr>
              <td>Device Information</td>
              <td>
                <p>
                  We obtain information such as your IP address, web browser
                  type, mobile operating system, and unique device identifiers.
                  This information is used for counting metrics internally and
                  with logging for errors as it relates to the Live365 Service.
                </p>
              </td>
            </tr>
            <tr>
              <td>Cookies & Related Technologies</td>
              <td>
                <p>
                  When you visit the Live365 Service, we and our third party
                  service providers receive and record information from your
                  browser and from cookies and similar technologies. Learn more
                  about how we use cookies and related technologies in our{' '}
                  <a href="/legal/cookies/" target="_blank" rel="noopener noreferral">
                    Cookie Policy
                  </a>
                  .
                </p>
              </td>
            </tr>
          </table>
        </PageSection>

        <PageSection>
          <h3>6. What do we use your personal data for?</h3>
          <table>
            <tr>
              <th>Categories of usage</th>
              <th>Description of category</th>
            </tr>
            <tr>
              <td>Internal and Service-Related Usage</td>
              <td>
                <p>
                  We use personal data for internal and Service-related
                  purposes, including to operate, provide, and maintain the
                  Live365 Service.
                </p>
              </td>
            </tr>
            <tr>
              <td>Analytics & Bug Reports</td>
              <td>
                <p>
                  We use the data we collect such as your location to monitor
                  the performance and health of the Live365 Service and
                  continually improve the experience.
                </p>
              </td>
            </tr>
            <tr>
              <td>Communication</td>
              <td>
                <p>
                  We may send emails to you for billing, customer or technical
                  support purposes, newsletters, or other topics we may find
                  relevant for you based on your subscription to the Live365
                  Service.
                </p>
              </td>
            </tr>
            <tr>
              <td>Advertising</td>
              <td>
                <p>
                  We and our advertising partners may use your personal data,
                  including location, to deliver advertisements targeted to you.
                </p>
              </td>
            </tr>
            <tr>
              <td>Aggregate Data</td>
              <td>
                <p>
                  We may de-identify and aggregate information collected through
                  the use of the Live365 Service for statistical analysis and
                  other lawful purposes.
                </p>
              </td>
            </tr>
          </table>
        </PageSection>

        <PageSection>
          <h3>7. Sharing your personal data</h3>

          <p>
            We have set out the categories of recipients of the personal data
            collected or generated through your use of the Live365 Service.
          </p>

          <h4>Publicly available information</h4>
          <p>
            Information including your station name, website URL, station logo,
            station profile cover art, social media account links, genres, and
            station description (‘Public Information’) will be available
            publicly and to be utilized by other Live365 users.
          </p>

          <h4>Non Affiliated Companies</h4>
          <p>
            We do not rent, sell, or share personal information about you with
            other people or non affiliated companies for their direct marketing
            purposes.
          </p>

          <h4>Social Networking & Other Websites</h4>
          <p>
            Live365 may allow you or other users to Public Information you
            submit to the Live365 Service with social networking and other
            sites, such as your station’s name with now playing information. We
            do not share your personal data with other websites unless you
            direct the Live365 Service to do so.
          </p>

          <h4>Search Engines</h4>
          <p>
            Your Public Information submitted to the Live365 Service will be
            available to search engines, such as Google or Bing, just like any
            normal social media service.
          </p>

          <h4>Other Third Parties</h4>
          <p>
            Live365 may disclose your personal data to third parties in the
            following circumstances:
            <ul>
              <li>
                <b>With Your Consent</b> We may disclose personal data. We may
                seek permission from you in different ways, such as an Opt In
                when signing up for the Live365 Service.
              </li>
              <li>
                <b>To Comply with Legal Obligations</b> We may access, preserve,
                and disclose personal data or other account information if we
                believe it is required to comply with laws or other legal
                obligations that may arise
              </li>
              <li>
                <b>In case of a Merger, Sale, or Other Asset Transfer</b> If we
                are involved in a merger, acquisition, financing due diligence,
                reorganization, bankruptcy, or liquidation, Live365 may be sold
                or transferred as part of a transaction to ensure continued
                success of the Live365 Service. The continued use of your
                personal data shall be governed by Live365’ Privacy Policy in
                effect at the time of the transaction.
              </li>
            </ul>
          </p>
        </PageSection>

        <PageSection>
          <h3>8. Data retention and deletion</h3>

          <p>
            We keep your personal data only as long as necessary to provide you
            with the Live365 Service and for legitimate and essential business
            purposes, such as maintaining the performance of the Live365
            Service, making data-driven business decisions about new features
            and offerings, complying with our legal obligations, and resolving
            disputes.
          </p>

          <p>
            We keep some of your personal data for as long as you are a user of
            the Live365 Service. For example, we keep your playlists, station
            information, media library, and account information.
          </p>

          <p>
            If you request, we will delete or anonymize your personal data so
            that it no longer identifies you, unless we are legally allowed or
            required to maintain certain personal data, including situations
            such as the following:
          </p>
          <p>
            <ul>
              <li>
                If there is an unresolved issue relating to your account, such
                as an outstanding credit on your account or an unresolved claim
                or dispute we will retain the necessary personal data until the
                issue is resolved;
              </li>
              <li>
                Where we are required to retain the personal data for our legal,
                tax, audit, and accounting obligations, we will retain the
                necessary personal data for the period required by applicable
                law; and/or,
              </li>
              <li>
                Where necessary for our legitimate business interests such as
                fraud prevention or to maintain the security of our users.
              </li>
            </ul>
          </p>
        </PageSection>

        <PageSection>
          <h3>9. Links</h3>
          <p>
            We may display advertisements from third parties and other content
            that links to third-party websites. We cannot control or be held
            responsible for third parties’ privacy practices and content. If you
            click on a third-party advertisement or link, please understand that
            you are leaving the Live365 Service and any personal data you
            provide will not be covered by this Policy. Please read their
            privacy policies to find out how they collect and process your
            personal data.
          </p>
        </PageSection>

        <PageSection>
          <h3>10. Keeping your personal data safe</h3>
          <p>
            We are committed to protecting our users’ personal data. We
            implement appropriate technical and organizational measures to help
            protect the security of your personal data; however, please note
            that no system is ever completely secure. We have implemented
            various policies including pseudonymization, encryption, access, and
            retention policies to guard against unauthorized access and
            unnecessary retention of personal data in our systems.
          </p>
          <p>
            Your password protects your user account, so we encourage you to use
            a unique and strong password, limit access to your computer and
            browser, and log out after having used the Live365 Service.
          </p>
        </PageSection>

        <PageSection>
          <h3>11. Children</h3>
          <p>
            The Live365 Service is not directed to children under the age of 13
            years. However, in some countries, stricter age limits may apply
            under local law. Please see our{' '}
            <a href="/legal/terms/" target="_blank" rel="noopener noreferral">
              Terms of Service
            </a>
            {' '}for further details.
          </p>
          <p>
            We do not knowingly collect personal data from children under 13
            years or under the applicable age limit (the “Age Limit”). If you
            are under the Age Limit, please do not use the Live365 Service, and
            do not provide any personal data to us.
          </p>
          <p>
            If you are a parent of a child under the Age Limit and become aware
            that your child has provided personal data to Live365, please
            contact us at privacy@live365.com, and you may request exercise of
            your applicable rights detailed in the ‘Your rights and your
            preferences: Giving you choice and control’ Section 3 of this
            Policy.
          </p>
          <p>
            If we learn that we have collected the personal data of a child
            under the age of 13 years, we will take reasonable steps to delete
            the personal data. This may require us to delete the Live365 account
            for that child.
          </p>
        </PageSection>

        <PageSection>
          <h3>12. Changes to this Privacy Policy</h3>
          <p>We may occasionally make changes to this Policy.</p>
          <p>
            When we make material changes to this Policy, we’ll provide you with
            prominent notice as appropriate under the circumstances, e.g., by
            displaying a prominent notice within the Live365 Service or by
            sending you an email. We may notify you in advance.
          </p>
          <p>
            Please, therefore, make sure you read any such notice carefully.
          </p>
        </PageSection>

        <PageSection>
          <h3>How to Contact Us</h3>
          <p>
            Thank you for reading our Privacy Policy. If you have any questions
            about this Policy, please contact us.
          </p>
          <address>
            <span className="address-line">Live365</span>
            <span className="address-line">1340 Monticello Avenue</span>
            <span className="address-line">Hermitage, PA 16148</span>
            <span className="address-line">privacy@live365.com</span>
          </address>
          <p>
            Live365 is the data controller for the purposes of the personal data
            processed under this Policy.
          </p>
        </PageSection>
      </Page>
    )
  }
}

export default Privacy
